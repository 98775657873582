import { Api } from 'lib/api/instance'
import { Analytic } from 'lib/helpers/Analytic'
import Widget from 'models/Widget'

export default {
  namespaced: true,
  state: {
    widget: new Widget(),
    checkingChatbot: false,
    togglingChatbot: false,
    sendingMessage: false,
    brandisExtensionChecking: false,
  },
  mutations: {
    setWidget (state, payload) {
      state.widget = payload
    },
    toggleCheckingChatbot (state, payload) {
      state.checkingChatbot = payload
    },
    toggleTogglingChatbot (state, payload) {
      state.togglingChatbot = payload
    },
    toggleSendingMessage (state, payload) {
      state.sendingMessage = payload
    },
    toggleBrandisExtensionStatus (state, payload) {
      state.widget.brandisExtensionActive = payload
    },
    toggleBrandisExtensionChecking (state, payload) {
      state.brandisExtensionChecking = payload
    },
  },
  actions: {
    async fetchWidget ({ commit, dispatch }, slug) {
      const res = await dispatch('send', `ads/widget/${slug}`)
      commit('setWidget', res.data)
    },
    toggleChatbot ({ state, dispatch }) {
      if (state.widget.platform === 'twitch') {
        if (state.widget.chatbotTwitchConnected) {
          dispatch('disconnectChatbot')
        }
        else {
          dispatch('connectChatbot')

          Analytic.vkgoal('customize_product')
          Analytic.fbq('track', 'CustomizeProduct')
        }
      }

      if (state.widget.platform === 'youtube') {
        if (state.widget.chatbotYoutubeConnected) {
          dispatch('disconnectChatbot')
        }
        else {
          dispatch('connectChatbot')

          Analytic.vkgoal('customize_product')
          Analytic.fbq('track', 'CustomizeProduct')
        }
      }

      if (state.widget.platform === 'trovo') {
        if (state.widget.chatbotTrovoConnected) {
          dispatch('disconnectChatbot')
        }
        else {
          dispatch('connectChatbot')

          Analytic.vkgoal('customize_product')
          Analytic.fbq('track', 'CustomizeProduct')
        }
      }
    },
    async connectChatbot ({ state, commit }) {
      try {
        commit('toggleTogglingChatbot', true)
        const res = await Api.get(`auth/nightbot/${state.widget.platform}`)
        window.location = res.src
      }
      finally {
        commit('toggleTogglingChatbot', false)
      }
    },

    async disconnectChatbot ({ state, commit }) {
      try {
        commit('toggleTogglingChatbot', true)
        await Api.get(`auth/nightbot/${state.widget.platform}/disconnect`)
        await state.widget.fetch()
      }
      finally {
        commit('toggleTogglingChatbot', false)
      }
    },
    async checkChatbot ({ state, commit }) {
      try {
        commit('toggleCheckingChatbot', true)
        await Api.get(`streamer/${state.widget.platform}/moderator`)
      }
      finally {
        commit('toggleCheckingChatbot', false)
      }
    },
    async sendMessage ({ state, commit }) {
      try {
        commit('toggleSendingMessage', true)
        await Api.get(`nightbot/${state.widget.platform}/messages/test`)
      }
      finally {
        commit('toggleSendingMessage', false)
      }
    },
    async sendPreview ({ commit }) {
      try {
        commit('toggleSendingPreview', true)
        await Api.get('ads/widget/demo')
      }
      finally {
        commit('toggleSendingPreview', false)
      }
    },
    async sendManualAd () {
      await Api.get('ads/widget/manual')
    },
    async refreshObsLink () {
      await Api.post('streamer/widget/obs/refresh')
    },

    async checkExtensionStatus ({ commit, dispatch }) {
      try {
        commit('toggleBrandisExtensionChecking', true)
        const status = await Api.get('streamer/extension/brandis/activation/check')
        if (status?.changed) {
          dispatch('campaigns/fetchCampaigns', {}, {root: true})
          commit('toggleBrandisExtensionStatus', status.activation)
        }
      }
      finally {
        commit('toggleBrandisExtensionChecking', false)
      }
    },
  },
  getters: {
    widget: ({ widget }) => widget,
    mode: ({ widget }) => widget?.mode,
    obsDockUrl: ({ widget }) => `${location.origin}/obs-dock/${widget?.slugObsDock}`,
    checkingChatbot: ({ checkingChatbot }) => checkingChatbot,
    togglingChatbot: ({ togglingChatbot }) => togglingChatbot,
    sendingMessage: ({ sendingMessage }) => sendingMessage,
    brandisExtensionStatus: ({ widget }) => widget.brandisExtensionActive,
    brandisExtensionChecking: ({ brandisExtensionChecking }) => brandisExtensionChecking,
  },
}
