<template>
  <div class="campaign">
    <div class="campaign__inner">
      <div class="campaign__left">
        <Avatar :campaign="campaign" />
      </div>
      <div class="campaign__right">
        <div class="campaign__right-top">
          <div class="text-m-regular campaign__title">
            {{ campaign.title }}
          </div>
        </div>
        <Tags :campaign="campaign" />
        <CampaignRowDailyLimit :campaign="campaign" />
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'components/CampaignRow/components/Avatar'
import CampaignRowDailyLimit from 'components/CampaignRow/components/indicators/CampaignRowDailyLimit.vue'
import Tags from 'components/CampaignRow/components/Tags'

export default {
  name: 'CampaignRow',

  components: {
    Avatar,
    Tags,
    CampaignRowDailyLimit,
  },

  props: {
    campaign: VueTypes.object,
  },
}
</script>

<style lang="scss" scoped>
.campaign {
  width: 100%;
  transition: $--all-transition;

  &:not(:last-child) {
    .campaign__inner {
      border-bottom: 1px solid #4D4D4D;
    }
  }

  &:first-child {
    .campaign__inner {
      padding-top: 0;
    }
  }

  &__inner {
    display: flex;
    padding: 16px 0;
  }

  &__left {
    flex-shrink: 0;
    margin-right: 16px;
  }

  &__right {
    width: calc(100% - 80px);

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
  }

  &__title {
    color: white;
  }

  ::v-deep {
    .campaign-row-limit {
      display: flex;

      .text-caption {
        margin-right: 5px;

        &:after {
          content: ':';
        }
      }
    }
    .campaign-row-limit * {
      font-size: 12px;
      line-height: 14px;
      color: white;
    }

    .campaign-row-tags {
      margin-bottom: 8px;

      &__item_primary {
        background-color: rgba(38, 111, 254, 0.2);
        color: #198CFE;
      }
    }
  }
}
</style>
