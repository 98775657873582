<template>
  <div>
    <ReloginMessage v-if="widget.relogin" />

    <div class="panel-page">
      <div
        v-if="authError"
        class="panel-page__error"
      >
        <SvgIcon
          name="x_x"
          width="148px"
          height="36px"
        />

        <div class="panel-page__error-title">
          {{ locale.error.title }}
        </div>
        <div class="panel-page__error-text">
          {{ locale.error.text }}
        </div>
      </div>
      <div
        v-else-if="!loaded"
        class="panel-page__preloader"
      >
        <SvgIcon
          name="uplify-logo"
          width="93px"
          height="32px"
        />
      </div>
      <div
        v-else
        class="panel-page__inner"
      >
        <Tabs :tab.sync="tab" />

        <div v-if="tab === 'widget'">
          <StatusSection />
          <SettingsSection />
        </div>
        <div v-if="tab === 'campaigns'">
          <CampaignsSection />
        </div>

        <div class="panel-page__footer">
          <Logger :log="log" />
          <Powered />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from 'lib/api/instance'
import { setToken } from 'lib/helpers/authToken'
import * as events from 'lib/pusher/constants/pusher-events'
import campaignsStore from 'Streamer/pages/StreamerCampaigns/store'
import widgetSettingsStore from 'Streamer/store/widgetSettings'
import WidgetLogger from 'views/Widget/Debug/class/WidgetLogger'
import Socket from 'views/WidgetOld/Socket'
import { mapActions } from 'vuex'

import CampaignsSection from './components/CampaignsSection'
import Logger from './components/Logger'
import Powered from './components/Powered'
import ReloginMessage from './components/ReloginMessage'
import SettingsSection from './components/SettingsSection'
import StatusSection from './components/StatusSection'
import Tabs from './components/Tabs'

export default {
  components: {
    Tabs,
    Logger,
    Powered,
    StatusSection,
    SettingsSection,
    CampaignsSection,
    ReloginMessage,
  },

  data: () => ({
    tab: 'widget',
    loaded: false,
    authError: false,
    platformIsChanged: false,
    logger: null,
    log: [],
  }),

  computed: {
    locale: ({ $rootLocale }) => ({
      error: $rootLocale('views.panel.error'),
    }),
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    user: ({ $store }) => $store.getters['auth/user'],
    token: ({ $route }) => $route.params.token,
    mode: ({ widget }) => widget?.advertising_mode,
  },

  watch: {
    'widget.platform' (value, prevValue) {
      if (prevValue) {
        this.platformIsChanged = true
      }
    },
    mode (val) {
      this.logger.setMode(val)
    },
  },

  beforeCreate () {
    if (!this.$store.hasModule('widgetSettings')) {
      this.$store.registerModule('widgetSettings', widgetSettingsStore)
    }
    if (!this.$store.hasModule('campaigns')) {
      this.$store.registerModule('campaigns', campaignsStore)
    }
  },

  async created () {
    try {
      await this.auth()
      await this.fetchStreamer()
      await this.fetchDictionaries()
      this.setLocale()
      await this.widget.fetch()
      await this.$store.dispatch('campaigns/fetchCampaigns')
      this.initSocket()
    }
    catch {
      this.authError = true
    }
    finally {
      this.loaded = true
    }
  },

  methods: {
    ...mapActions({
      fetchStreamer: 'auth/FETCH_STREAMER',
      fetchDictionaries: 'dictionaries/fetchDictionaries',
    }),

    async auth () {
      try {
        const res = await Api.get(`auth/streamer/obs/${this.token}`)

        if (res.status) {
          setToken(res.token, 'streamer')
        }
        else {
          throw new Error(res)
        }
      }
      catch (err) {
        throw new Error(err)
      }
    },
    setLocale () {
      this.$root.$i18n.locale = this.user.locale
      localStorage.setItem('locale', this.user.locale)
    },
    initSocket () {
      this.logger = new WidgetLogger(this.widget.slug)
      this.log = this.logger.value
      this.socket = new Socket(this.widget.slug)
      this.socket.listen((event, payload) => {
        this.logger.handleEvent(event, payload)
        if (event === events.WIDGET_UPDATED) {
          this.widget.updateData(payload)
          if (this.platformIsChanged) {
            this.$store.dispatch('campaigns/fetchCampaigns')
            this.platformIsChanged = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$--color-primary: #198cfe;
$--color-label: #b2b2b2;

.panel-page {
  display: flex;
  flex-direction: column;
  background-color: #1f1e1f;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: white;
  overflow-y: auto;

  &__preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      fill: $--color-primary;
    }
  }

  &__error {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    svg {
      fill: $--color-primary;
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: -0.02em;
      margin-top: 25px;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 428px;
    width: 100%;
    position: relative;
    margin: 0 auto;
  }

  ::v-deep {
    .el-form-item__label,
    .widget-box-size__top .text-s-regular {
      color: $--color-label;
    }
    .widget-box-size__range-line {
      border-color: $--color-label;
    }
    .widget-box-size__range-point_active {
      color: $--color-primary;
    }
    .widget-box-size__range-point:before {
      background-color: $--color-label;
    }
    .widget-box-size__range-point_active:before {
      background-color: white;
      box-shadow: 0 0 0 8px $--color-primary;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding-top: 30px;
  }
}
</style>
