<template>
  <div class="panel-logger">
    <ElButton
      v-if="widget"
      class="panel-logger__button"
      type="text"
      @click="copyLog"
    >
      <span class="text-caption">{{ isPressed ? locale.logCopied : locale.copyLog }}</span>
    </ElButton>
  </div>
</template>

<script>
import ElButton from 'element/ElButton'
import VueTypes from 'vue-types'

export default {
  components: {
    ElButton,
  },

  props: {
    log: VueTypes.Array,
  },

  data: function () {
    return {
      logger: null,
      isPressed: false,
    }
  },

  computed: {
    widget: ({ $store }) =>
      $store.getters['widgetSettings/widget'],
    locale: ({ $rootLocale }) => ({
      copyLog: $rootLocale('views.panel.logger.copy'),
      logCopied: $rootLocale('views.panel.logger.copied'),
    }),
  },

  methods: {
    copyLog () {
      if (this.isPressed) return

      const encodedLog = JSON.stringify(this.log)
      this.$clipboard(encodedLog)

      this.isPressed = true
      setTimeout(() => {
        this.isPressed = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-logger {

  &__button {
    margin-bottom: 8px;
    padding: 5px 0;
    color: #b2b2b2;
  }
}
</style>
