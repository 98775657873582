import debounce from 'lodash/debounce'

import BaseModel from '@/models/BaseModel'

export default class Widget extends BaseModel {
  constructor () {
    super()

    this.debouncedUpdate = debounce(this.update, 1000)

    this.on('change-prop', ({ prop }) => {
      // ignore categories updates in component
      if (prop === 'ignoreCategories') return
      this.debouncedUpdate()
    })
  }

  static routes = {
    fetch: 'streamer/widget',
    update: 'streamer/widget',
  }

  static schema = {
    slug: '',
    url: '',
    ad_manual_enable: false,
    advertising_mode: 'auto',
    advertising_frequency: 30,
    advertising_position: '',
    leaderboard_position: '',
    ignore_categories: [],
    widget_active: false,
    stream_active: false,
    stream_delay: 0,
    extension_active: false,
    bot_active: false,
    box_size: 3,
    nightbot_twitch_connected: false,
    nightbot_twitch_moderator: false,
    nightbot_youtube_connected: false,
    nightbot_youtube_moderator: false,
    nightbot_trovo_connected: false,
    nightbot_trovo_moderator: false,
    slug_obs_dock: '',
    platform: '',
    referral_promotion: false,
    relogin: false,
    brandis_extension_active: false,
    allow_adult_content: true,
    enable_chatbot_text_ads: true,
    enable_ssp_instream_ads: true,
    ssp_text_frequency: 15,
  }

  static aliases = {
    ad_manual_enable: 'adManualEnable',
    advertising_mode: 'mode',
    advertising_frequency: 'frequency',
    advertising_position: 'advertisingPosition',
    leaderboard_position: 'leaderboardPosition',
    ignore_categories: 'ignoreCategories',
    widget_active: 'enabled',
    stream_active: 'stream',
    stream_delay: 'streamDelay',
    bot_active: 'chatbotEnabled',
    box_size: 'boxSize',
    extension_active: 'extensionEnabled',
    nightbot_twitch_connected: 'chatbotTwitchConnected',
    nightbot_twitch_moderator: 'chatbotTwitchIsModerator',
    nightbot_youtube_connected: 'chatbotYoutubeConnected',
    nightbot_youtube_moderator: 'chatbotYoutubeIsModerator',
    nightbot_trovo_connected: 'chatbotTrovoConnected',
    nightbot_trovo_moderator: 'chatbotTrovoIsModerator',
    slug_obs_dock: 'slugObsDock',
    referral_promotion: 'promotion',
    brandis_extension_active: 'brandisExtensionActive',
    allow_adult_content: 'allowAdultContent',
    enable_chatbot_text_ads: 'enableChatbotTextAds',
    enable_ssp_instream_ads: 'enableSspInstreamAds',
    ssp_text_frequency: 'sspTextFrequency',
  }
}
