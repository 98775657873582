<template>
  <div>
    <Section
      :title="locale.platformSelectTitle"
    >
      <WidgetPlatformSelect select-theme="_theme-dark" />
    </Section>
    <Section
      :title="title"
      :count="filtered.length ? filtered.length.toString() : undefined"
    >
      <div
        v-loading="loading"
        class="panel-campaign-section"
      >
        <Campaign
          v-for="item in filtered"
          :key="item.id"
          :campaign="item"
        />
        <div
          v-if="!filtered.length"
          class="panel-campaign-section__none"
        >
          <img
            :src="require('assets/img/obs-dock-no-campaigns.jpg')"
            alt="Obs Dock No Campaigns"
          >

          <div
            class="panel-campaign-section__none-text"
            v-html="locale.comeBack"
          />
        </div>
      </div>
    </Section>
  </div>
</template>

<script>
import WidgetPlatformSelect from 'components/WidgetPlatformSelect'
import loading from 'element/Loading'

import Campaign from './Campaign'
import Section from './Section'

export default {
  components: {
    Section,
    Campaign,
    WidgetPlatformSelect,
  },

  directives: {
    loading,
  },

  computed: {
    locale: ({ $rootLocale }) => ({
      title: $rootLocale('views.panel.campaigns.title'),
      none: $rootLocale('views.panel.campaigns.none'),
      comeBack: $rootLocale('views.panel.campaigns.comeBack'),
      platformSelectTitle: $rootLocale('views.streamer.widget.widgetPlatform.title'),
    }),
    title: ({ locale, campaigns }) => campaigns.length ? locale.title : locale.none,
    campaigns: ({ $store }) => $store.getters['campaigns/activeCampaigns'],
    loading: ({ $store }) => $store.getters['campaigns/loading'].campaigns.incompleted,
    filtered ({ campaigns }) {
      return campaigns.filter(item => {
        return !(item.impressions >= item.daily_limit || item.rest_limit_percent <= 5)
      })
    },
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
  },
}
</script>

<style lang="scss" scoped>
.panel-campaign-section {
  :deep {
    .el-loading-mask {
      background-color: rgba(#1F1E1F, 0.9);
    }

    .el-loading-spinner .path {
      stroke: #fff;
    }
  }

  &__none {
    &-text {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      text-align: center;
      margin-top: 24px;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
