<template>
  <div class="panel-tabs">
    <ElTabs
      :value="tab"
      @input="$emit('update:tab', $event)"
    >
      <ElTabPane name="widget">
        <template #label>
          <SvgIcon
            name="gear"
            width="18px"
            height="18px"
          />
          {{ locale.widget }}
        </template>
      </ElTabPane>
      <ElTabPane name="campaigns">
        <template #label>
          <SvgIcon
            name="creative"
            width="18px"
            height="18px"
          />
          {{ locale.campaigns }}
        </template>
      </ElTabPane>
    </ElTabs>
  </div>
</template>

<script>
import ElTabPane from 'element/ElTabPane'
import ElTabs from 'element/ElTabs'

export default {
  components: {
    ElTabs,
    ElTabPane,
  },

  props: {
    tab: VueTypes.string,
  },

  computed: {
    locale: ({ $rootLocale }) => ({
      widget: $rootLocale('views.panel.tabs.widget'),
      campaigns: $rootLocale('views.panel.tabs.campaigns'),
    }),
  },
}
</script>

<style lang="scss" scoped>
$--color-primary: #198CFE;

.panel-tabs {
  &::v-deep {
    .el-tabs {
      &__nav {
        display: flex;
        width: 100%;

        &-wrap:after {
          background-color: #4D4D4D;
        }
      }
      &__active-bar {
        background-color: $--color-primary;
      }
      &__header {
        margin-bottom: 24px;
      }
      &__item {
        width: 100%;
        height: auto;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        font-size: 14px;
        text-transform: none;
        color: white;
        padding: 0 0 17px;

        .svg-icon {
          margin-right: 10px;
          fill: white;
        }

        &.is-active {
          color: $--color-primary;

          .svg-icon {
            fill: $--color-primary;
          }
        }
      }
    }
  }
}
</style>
