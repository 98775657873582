<template>
  <Section :title="locale.title">
    <div class="panel-settings-section">
      <ElForm class="panel-settings-section__form">
        <WidgetAdPlaybackMode />
        <WidgetManualPlayback v-if="mode === 'manual'" />
        <WidgetBannerTimeout
          v-if="mode === 'auto'"
          select-theme="_theme-dark"
        />
        <WidgetBoxSize />
        <WidgetChatbotSendPreview :label="locale.previewCaption" />
        <Section :title="locale.locationCaption">
          <WidgetAdvertisingPosition select-theme="_theme-dark" />
        </Section>
      </ElForm>
    </div>
  </Section>
</template>

<script>
import ElForm from 'element/ElForm'
import WidgetAdPlaybackMode from 'Streamer/pages/StreamerWidget/components/WidgetAdPlaybackMode'
import WidgetAdvertisingPosition from 'Streamer/pages/StreamerWidget/components/WidgetAdvertisingPosition'
import WidgetBannerTimeout from 'Streamer/pages/StreamerWidget/components/WidgetBannerTimeout'
import WidgetBoxSize from 'Streamer/pages/StreamerWidget/components/WidgetBoxSize'
import WidgetChatbotSendPreview from 'Streamer/pages/StreamerWidget/components/WidgetChatbotSendPreview'
import WidgetManualPlayback from 'Streamer/pages/StreamerWidget/components/WidgetManualPlayback'

import Section from './Section'

export default {
  components: {
    ElForm,
    WidgetChatbotSendPreview,
    WidgetAdPlaybackMode,
    WidgetManualPlayback,
    WidgetBannerTimeout,
    WidgetBoxSize,
    WidgetAdvertisingPosition,
    Section,
  },

  computed: {
    locale: ({ $rootLocale }) => $rootLocale('views.panel.params'),
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    mode: ({ widget }) => widget?.mode,
    displayWarning: ({ widget }) => {
      return !(widget?.stream && widget?.enabled && widget?.chatbotEnabled) // && widget?.extensionEnabled
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-settings-section {
  ::v-deep {
    .widget-send-preview {
      width: 100%;
    }

    .widget-box-size {
      margin-bottom: 36px;
    }
  }

  &__form {
    margin-top: 24px;

    ::v-deep {
      .el-radio-button {
        &:first-child .el-radio-button__inner:hover {
          border-left-color: #4D4D4D;
        }
        &__inner {
          background-color: transparent;
          border: 1px solid #4D4D4D;
          color: white;
        }

        &.is-active {
          .el-radio-button__inner {
            background-color: rgba(25, 140, 254, 0.1);
            border: 1px solid #198CFE !important;
            color: #198CFE;
          }
        }
      }
    }
  }
}
</style>
