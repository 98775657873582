<template>
  <Section :title="locale.title">
    <div class="panel-status-section">
      <WidgetStatus
        locale-name="stream"
        field-name="stream"
      />
      <WidgetStatus
        v-if="false"
        locale-name="extension"
        field-name="extensionEnabled"
      />
      <WidgetStatus
        locale-name="widget"
        field-name="enabled"
      />
      <WidgetStatus
        locale-name="chatbot"
        field-name="chatbotEnabled"
      />
    </div>
    <div
      v-if="displayWarning"
      class="panel-status-section__warning"
    >
      {{ locale.statusesMustBeOn }}
    </div>
  </Section>
</template>

<script>
import WidgetStatus from 'Streamer/pages/StreamerWidget/components/WidgetStatus'

import Section from './Section'

export default {
  components: {
    WidgetStatus,
    Section,
  },

  computed: {
    locale: ({ $rootLocale }) => ({
      title: $rootLocale('views.panel.states.title'),
      statusesMustBeOn: $rootLocale('views.panel.statusesMustBeOn'),
    }),
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    displayWarning: ({ widget }) => {
      return !(widget?.stream && widget?.enabled && widget?.chatbotEnabled) // && widget?.extensionEnabled
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-status-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: -16px;

  .widget-status {
    display: flex;
    justify-content: space-between;
    width: calc(50% - 8px);
    margin-bottom: 16px;
  }

  &__warning {
    text-align: center;
    background-color: rgba(white, 0.1);
    border-radius: $--border-radius-base;
    padding: 16px;
    margin-top: 36px;
  }

  ::v-deep {
    .el-tag {
      width: 40px;
      text-align: center;
    }
  }
}
</style>
