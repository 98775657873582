<template>
  <div class="panel-section">
    <div
      v-if="title || count"
      class="panel-section__title"
    >
      <div
        v-if="title"
        class="panel-section__title-text"
      >
        {{ title }}
      </div>
      <div
        v-if="count"
        class="panel-section__title-counter"
      >
        {{ count }}
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: VueTypes.string,
    count: VueTypes.string,
  },
}
</script>

<style lang="scss" scoped>
.panel-section {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &-text {
      font-weight: 700;
      margin-right: 8px;
    }

    &-counter {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      font-size: 10px;
      font-weight: 700;
      background-color: #198CFE;
      border-radius: 50%;
    }
  }

  &:not(:first-child) {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #4D4D4D;
  }
}
</style>
